import React, { Fragment } from 'react';
import { PageProps } from 'gatsby';
import { withTranslation, WithTranslation, Trans } from 'react-i18next';
// import ReactPlayer from 'react-player/youtube';

import Layout, { LayoutOptions, HeroSectionColors } from '../../../components/Layout/Layout';
import { LocaleNameSpaceKeys } from '../../../localization/translations';
import PageMenu from '../../../components/PageMenu/PageMenu';
import { Accordion, AccordionItem } from '../../../components/Accordion/Accordion';
import aboutUsMenu from '../../../data/page-menus/about-us';
import { getPathFromRouteKey } from '../../../shared/utils/getPathFromRouteKey';
import RouteKeys from '../../../../config/RouteKeys';
import { useLocaleContext } from '../../../context/Locale';
// import { LocaleTag } from '../../../../config/locales';
import Link from '../../../components/Link/Link';
import ExternalLink from '../../../components/ExternalLink/ExternalLink';
import CommonData from '../../../data/common/common';
import './style.scss';

type Props = PageProps & WithTranslation;

const SCC: React.FC<Props> = (props: Props) => {
  const { t } = props;
  const { activeLocaleTag } = useLocaleContext();

  const layoutOptions: LayoutOptions = {
    seo: {
      title: t('scc.seo.title'),
    },
    page: {
      title: t('scc.heading'),
    },
    heroSection: {
      options: {
        accentColor: HeroSectionColors.CHERRY_RED,
        heroImage: {
          fileName: 'hero-image-scc-2.jpg',
          alt: 'Image',
        },
      },
    },
  };

  const generateContent = (item: any) => {
    return item.bodies.map((body: any, index: number) => (
      <Fragment key={`content-${index}`}>
        <Trans
          {...(item.bodyLinks && item.bodyLinks[index] && item.bodyLinks[index].length
            ? {
                components: item.bodyLinks[index].map((bLink: { path: string; type: string }) =>
                  bLink.type === 'internal' ? (
                    <Link
                      to={getPathFromRouteKey(
                        bLink.path
                          .split('.')
                          .reduce((accum: any, next: any) => accum[next], RouteKeys)
                      )}
                    />
                  ) : (
                    <ExternalLink
                      href={
                        bLink.path
                          .split('.')
                          .reduce(
                            (accum: any, next: any) => accum[next],
                            CommonData.websites as any
                          )[activeLocaleTag]
                      }
                    />
                  )
                ),
              }
            : {})}
        >
          {body}
        </Trans>
        {item.lists[index] && item.lists[index].length ? (
          <ul>
            {item.lists[index].map(
              (listItem: { text: string; links?: { path: string; type: string }[] }, i: number) => (
                <li key={`item-${i}`}>
                  <Trans
                    {...(listItem.links
                      ? {
                          components: listItem.links.map((link) =>
                            link.type === 'internal' ? (
                              <Link
                                to={getPathFromRouteKey(
                                  link.path
                                    .split('.')
                                    .reduce((accum: any, next: any) => accum[next], RouteKeys)
                                )}
                              />
                            ) : (
                              <ExternalLink
                                href={(CommonData.websites as any)[link.path][activeLocaleTag]}
                              />
                            )
                          ),
                        }
                      : {})}
                  >
                    {listItem.text}
                  </Trans>
                </li>
              )
            )}
          </ul>
        ) : null}
      </Fragment>
    ));
  };

  const generateList = (list: any[]) => {
    return (
      <Accordion allowZeroExpanded allowMultipleExpanded>
        {list.map((item, i) => {
          return (
            <AccordionItem key={`${item.title}-${i}`} buttonOptions={{ label: item.title }}>
              {generateContent(item)}
            </AccordionItem>
          );
        })}
      </Accordion>
    );
  };

  return (
    <Layout options={layoutOptions} className="SCC">
      <div className="grid-container">
        <div className="row row-wrap">
          <section className="column column-60">
            <div className="SCC__generic-gap">
              <h2 className="h3">{t('scc.commitment.heading')}</h2>
              <p>
                <Trans i18nKey="scc.commitment.body" t={t}>
                  <ExternalLink href={CommonData.websites.cba.volSeniors[activeLocaleTag]} />
                  <ExternalLink href={CommonData.websites.canadaCa.seniors[activeLocaleTag]} />
                </Trans>
              </p>
            </div>
            <div className="SCC__generic-gap">
              <h2 className="h3">{t('scc.principles.heading')}</h2>
              {generateList(t('scc.principles.content', { returnObjects: true }))}
            </div>
          </section>
          <aside className="column column-33">
            <PageMenu menu={aboutUsMenu} />
          </aside>
        </div>
      </div>
    </Layout>
  );
};

export default withTranslation(LocaleNameSpaceKeys.ABOUT_US)(SCC);
